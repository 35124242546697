.pricing-details{
    margin-top: 20px;
}
.plan-details{
    margin-top: 20px;
}

.coupon-btn {
    background-color: #00263E !important;
    color: white !important;
}

.coupon-cancel-btn {
    margin-right: 30px;
    width: 100px;
}
.disclaimer-link{
    color: blue;
    text-decoration: underline !important;
    cursor: pointer;
}
.disclaimer-link:hover{
    color: blue;
}



.mt-30 {
    margin-top: 30px;
}

.member-cancel-btn {
    margin-right: 30px;
    width: 100px;
}

.member-save {
    background-color: #00263E !important;
    color: white !important;
    width: 100px;
    padding: 10px !important;
}

.text-success {
    color: #497637 !important;
}

.cancel-coupon {
    color: red;
    font-weight: bold;
    border: 1px solid;
    border-radius: 50%;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coupon-tag {
    border: 2px dashed;
    display: flex;
    height: 35px;
    align-items: center;
    font-size: 15px;
    padding: 10px;
}

.disable-input {
    cursor: not-allowed !important;
    pointer-events: none;
}

.terms-link {
    color: blue;
    text-decoration: underline !important;
}