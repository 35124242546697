@media print {
    // Home
    .home-main,
    .home-main * {
        visibility: visible !important;
        break-inside: avoid;
    }

    // advance-search
    .advance-search,
    .advance-search * {
        visibility: visible !important;
    }

    .items-for-advance-search,
    .items-for-advance-search * {
        visibility: visible !important;
        break-inside: avoid;
    }

    // Card view
    .card-view-datatable,
    .card-view-datatable * {
        visibility: visible !important;
        break-inside: avoid;
        .col,
        .item-details,
        .item-details * {
            break-inside: avoid;
        }
    }

    // More items, Watching, Bidding
    .view-more-items-main,
    .view-more-items-main *,
    .watching-page *,
    .watching-page,
    .bidding-page,
    .bidding-page * {
        visibility: visible !important;
        break-inside: avoid;
    }

    // Won, Not-won
    .won-page,
    .won-page *,
    .not-won-page,
    .not-won-page * {
        visibility: visible !important;
    }

    .won-and-not-won-datatable-main,
    .won-and-not-won-datatable-main * {
        visibility: visible !important;
        .col,
        .col *,
        .content-container,
        .content-container * {
            break-inside: avoid;
        }
    }

    // My Account
    .personal-info,
    .personal-info *,
    .change-password-main,
    .change-password-main *,
    .tax-exemptions-main,
    .tax-exemptions-main *,
    .invoice-list-main,
    .invoice-list-main *,
    .notification-preferences-main,
    .notification-preferences-main * {
        visibility: visible !important;
        break-inside: avoid;
    }

    // locations list
    .locations-list-main,
    .locations-list-main *,
    .sales-list-main,
    .sales-list-main * {
        visibility: visible !important;
        break-inside: avoid;
    }

    // Door View
    .image-content,
    .image-content * {
        visibility: visible !important;
        break-inside: avoid;
    }

    // Estate sale
    .estate-sale-list,
    .estate-sale-list *,
    .estate-sale-category,
    .estate-sale-category * {
        visibility: visible !important;
        break-inside: avoid;
    }

    .estate-sale-category {
        .handle-sidenav {
            display: none;
        }
    }
}
