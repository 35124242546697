
@import "../../../../shared/scss/partials/variables"; 

.memberservice {
    color: $ctbids-dark;
    .linktag {
        text-decoration: underline;

    }
    .mail-link{
        color:  $ctbids-dark;
    }
}