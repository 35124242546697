
#membership-modal-main {
    .modal-close-icon {
        margin-top: -27px;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 0%;
        .close-icon {
            height: 27px;
            width: 27px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.8rem;
            border-radius: 0px;
            color: rgb(208, 223, 0) !important;
        }
    }
    .membership-text {
        position: absolute;
    }
    .member-font {
        color: white;
        font-size: 25px;
    }
    .modal-pm {
        position: relative;
    }
    .modal-body {
        padding: 0px !important;
    }
    .member-btn {
        left: 16%;
        position: absolute;
        top: 77%;    
    }
    .member-btn-join {
        width: 100%;
        background-color: #cfdd28;
        border-radius: 5px;
        font-weight: bold;
        border: none;
        color: #03273e;
        font-size: 1rem;
        font-weight: bolder;
        padding: 5px;
    }
}

@media (min-width: 360px) and (max-width: 460px) {
    .member-btn {
        left: 15% !important; 
    }
    .member-btn-join {
        font-size: 0.8rem !important;
    }
}

@media (min-width: 180px) and (max-width: 361px) {
    .member-btn {
        left: 14% !important; 
    }
    .member-btn-join {
        font-size: 0.6rem !important;
    }
}