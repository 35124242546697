.personal-interest {
    overflow: auto;
    .fs-15 {
        font-size: 15px;
    }
    .fs-20 {
        font-size: 20px;
    }
    .hd-clr {
        color: #00263E;
    }
    .hd-ct-clr {
        color: #A50050;
        white-space: nowrap;
    }
    .w-25 {
        width: 25%;
    }
    .w-40 {
        width: 50%;
    }
    .w-60 {
        width: 60%;
    }
    .profile-rounded {
        width: 100px;
        height: 100px;
        margin-left: 20px;
    }
    .closest-match-container{
        width: 400px;

    }
    @media (max-width: 1450px) {
        
        .personal-interest{
            .closest-match-container{
                width: 30%;
            }
        }
    }

    @media (min-width: 280px) and (max-width: 1400px) {
        .w-50 {
            width: 100% !important;
        }
        .w-25 {
            width: 35% !important;
        }
        .w-40 {
            width: 80% !important;
        }
        .w-60 {
            width: 100% !important;
        }
    }

    @media (min-width: 280px) and (max-width: 820px) {
        .hd-ct-clr {
            word-break: break-word !important;
            white-space: normal !important;
        }
    }

    @media (min-width:1480px ) and (max-width: 1559px) {
        .mobile-resp-pi {
            margin-left: 26% !important;
        }
        .mobile-pi-resp-checkbox {
            margin-left: 10px !important;
        }
    }
}