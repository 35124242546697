@import "/src/shared/scss/partials/variables";

.memberserivce {
    color: $ctbids-dark;
    span{
        cursor: pointer;
    }

    .fs-20 {
        font-size: 20px;
    }

    .fs-30 {
        font-size: 30px;
    }

    .w-80 {
        width: 80px;
    }

    .service-img {
        width: 80px;
    }

    .service-btn {
        margin-top: 10px;
        padding: 7px;
        border-radius: 10px;
        border: none;
        width: 300px;
    }
    
    .ml-50 {
        margin-left: 50px;
    }
}

@media (min-width: 280px) and (max-width: 680px) {
    .memberserivce {
        .mobile-resp {
            flex-wrap: nowrap !important;
            flex-direction: column !important;
            align-items: center !important;
        }
        .mobile-wid {
            width: 100% !important;
        } 
        .mobile-foot-resp {
            flex-direction: column !important;
        }  
        .ml-50 {
            margin-left: 0px !important;
        }
        .service-btn {
            width: 250px;
        }
        .footer-resp {
            word-break: break-word !important;
        }
    }
}

.delivery-title {
    margin: 1px 20px;
    padding-top: 10px;
}