.our-story {
    .image-container {
        display: flex;
        justify-content: center;

        .ct-image {
            float: left;
        }

        .ctbids-logo {
            float: right;
            height: 150px;
        }
    }

    .our-story-message {
        margin-top: 15px;
        padding: 0% 1%;
        font-weight: 600;
        text-align: justify;
        color: #00263E;
    }

    .berry-color {
        font-style: italic;
        color: #A50050;
    }

    .berry-bold {
        color: #A50050;
        font-weight: bold;
    }

    .how-it-works {
        width: 100%;
        height: 80px;
        background-color: #00263E;
        text-align: center;
        color: white;
        font-size: 50px;
        font-weight: bold;
    }

    .img-content {
        float: left;
        width: 100px;

        .inside-image {
            margin: 15%;
            width: 100px;
            margin-left: 15px;
        }
    }

    .text-content {
        margin-left: 40px;
    }

    @media (max-width: 900px) {

        .image-container {
            .ct-image {
                width: 100%;
            }

            .ctbids-logo {
                width: 90%;
                height: 140px;
            }
        }
    }

    @media (max-width: 750px) {
        .our-story-img {
            height: 150px;
        }

        .image-container {
            .ct-image {
                width: 150px;
            }

            .ctbids-logo {
                width: 120px;
                height: 52px;
            }
        }
        .img-content {
            float: left;
            width: 100px;

            .inside-image {
                margin: 65%;
                width: 100px;
                margin-left: 15px;
            }
        }
    }

    @media (max-width: 400px) {

        .our-story-message {
            font-size: 13px;

        }

    }

}