@import "../../../../shared/scss/partials/variables"; 

.auto-renew {
    color: $ctbids-dark;
    padding: 30px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .logo-container {
      text-align: center;
      width: 50%;
  
      img {
        width: 150px;
        text-align: center;
      }
    }
  
    .body-container {
      text-align: center;
      width: 100%;
  
      .data-item {
        display: flex;
        justify-content: center;
  
        .content {
          width: 100%;
          max-width: 600px;
          border: 1px solid white;
          background-color: white;
          text-align: center;
  
          .title {
            margin-top: 20px;
            font-weight: bold;
            color: #a50050;
          }
  
          .description {
            margin-top: 30px;
          }
  
          .logo-button-container {
            display: grid;
            justify-content: center;
  
            .logo {
              margin-top: 10px;
              width: 200px;
            }
  
            .button {
              margin-top: 10px;
              background-color: #1d8296;
              padding: 7px;
              border-radius: 10px;
              border: none;
              width: 200px;
            }
          }
        }
      }
    }
  }
  