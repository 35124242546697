.thank-you {
    .logo-image {
        height: 75px;
        width: auto;
    }
    .f-25 {
        font-size: 25px;
    }
    .f-15 {
        font-size: 15px;
    }
}