@import "/src/shared/scss/partials/variables";

.vip-member {
    .profile-image {
        .profile-rounded {
            // border-radius: unset !important;
            width: 100px;
            height: 100px;
        }
        .add-text {
            margin-left: 10px;
        }
        .f-16 {
            font-size: 16px;
        }
    }
   
   
}
.afa-img{
    width: 115px;
}
.terms-link{
    text-decoration: underline !important;
}
.subscirbeView{
    display: flex;
    width: 100%;
    margin-top: 30px;
    background-color: #00263E;
    padding-top: 20px;
    color: white;
    padding-bottom: 20px;
    padding-left: 30px;
    .planBtn{
        color: #A50050;
        background-color: white;
        width: 200px;
        border: 2px solid $ctbids-neon; 
        border-radius: 10px;
        font-weight: bold;
    }
}
.heading-section{
    padding: 50px;
    .heading-content{
        text-align: center;
        font-weight: bold;       
    }

}

.offer-content{
    display: flex;
    
}
.benefits-heading{
    font-size: 40px;
    color: #1d8296 ;
}

@media (max-width: 800px) {
    .benefits-heading{
        font-size: 35px;
    } 
}
@media (max-width: 500px) {
    .heading-section{
        padding: 10px;
     
    
    }
    .offer-content{
        display: block;
        margin-bottom: 10px;
        
    }
    .afa-img{
        width: 100px;
    }
    .benefits-heading{
        font-size: 25px;
    }

    
}