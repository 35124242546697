@import "/src/shared/scss/partials/variables";

.worthpoint {
    color: $ctbids-dark;
    .worthpoint-content{
        a {
            color: black;
            text-decoration: underline !important;
            
        }
    }
   
}