@import "../../../../shared/scss/partials/variables";

.pma {
    .title {
        
        h1 {
            color: $ctbids-dark;
        }

        h6 {
            color: #a50050;
            font-weight: bold;
        }
    }

    .qa {
        .qaview {
            padding: 10px 20px;
        }

        .ques {
            display: flex;
            align-items: center;

        }

        .circle {
            width: 30px;
            height: 30px;
            background-color: #0f7590;
            text-align: center;
            border-radius: 15px;
            align-content: center;
            color: #fff;
            margin-right: 10px;
        }

        .submit {
            padding: 8px 40px;
            border-radius: 50px;
            background-color: #0f7590;
            border: 1px solid #0f7590;
            color: #fff;
            font-weight: 400;
        }

        .yes_radio_circle {
            width: 20px;
            height: 20px;
            border-radius: 15px;
            border: 2px solid #0f7590;
            margin-right: 10px;
            text-align: -webkit-center;
            align-content: center;
        }
        .no_radio_circle {
            width: 20px;
            height: 20px;
            border-radius: 15px;
            border: 2px solid red;
            margin-right: 10px;
            text-align: -webkit-center;
            align-content: center;
        }

        .radio_incircle {
            background: #0f7590;
            width: 80%;
            height: 80%;
            border-radius: 15px;
        }
        .no_radio_incircle {
            background: red;
            width: 80%;
            height: 80%;
            border-radius: 15px;
        }

        .input {
            margin: 20px 50px;
            width: 70%;
            padding: 10px;
            border: 1.3px solid #000;
            height: 100px;
        }

        .radio {
            display: flex;
            padding: 0px 100px;
        }

        .radio_item {
            display: flex;
            align-items: center;
            padding: 0px 20px;
            margin-top: 20px;
            margin-bottom: 25px;
        }

        .checkbox {
            border: 1px solid #0f7590;
            width: 30px;
            height: 30px;
            margin: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .check {
            width: 20px;
            height: 20px;
        }
    }

    .logobid {
        width: 90px;
    }
    .pmtoast {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #a50050;
    }
}

@media (min-width: 210px) and (max-width: 510px) {
    .pma .qa .input {
        margin: 20px 0px !important;
        width: 100% !important;
    }
    .pma .qa .radio {
        margin-top: 10px;
        padding: 15px 10px;
    }   
}

@media (min-width: 520px) and (max-width: 910px) {
    .pma .qa .input {
        margin: 20px 30px;
        width: 90%;
    }
    .pma .qa .radio {
        margin-top: 10px;
        padding: 15px;
    }   
}

.advisor-logo {
    width: 80px;
    height: 80px;
}
