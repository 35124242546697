@import "../../../../shared/scss/partials/variables"; 

.search-header-content {
    background-color: #fff9e9;
}

.p-10 {
    padding: 10px;
}

.search-text {
    padding: 5px 15px;
    border-width: 0px;
}

.search-box {
    width: 100%;
    color: $search-input-border;
    input {
        border: transparent;
    }
    .form-control,
    .form-control:focus {
        font-weight: 400 !important;
        border-color: transparent !important;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .input-group {
        border: 1px solid $search-input-border;
        color:$search-input-border ;
        background: white;
        padding: 0 8px 0 4px;
        width: 500px;
        border-radius: 0.25rem;
        .btn-outline-primary {
            border: transparent;
            background-color: white;
        }
        .btn-outline-primary:hover {
            color: black;
        }
        .btn-outline-primary:focus {
            border: transparent;
        }
    }
}

.clear-btn {
    background-color: #00263E !important;
}

.iframe-border {
    border: none !important;
}

.fs-20 {
    font-size: 20px;
}

.plus {
    padding: 0;
    list-style-type: none;
    font-weight: bold;
}
.logo-img {
    width: 80px;
    height: 80px;
}

.ft-navy {
    color: #00263E;
}

.contact-box {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.fs-17 {
    font-size: 17px;
}