html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
}

#jsd-widget {
    // right: 14px !important;
    #button-container {
        display: none !important;
    }
}
#react-root {
    display: none;
}
#jsd-widget > #button-container {
    display: none !important;
}

@media (max-width: 991px) {
    #jsd-widget {
        top: 24px;
        // right: 41px !important;
    }
    #jsd-widget.login {
        top: 27px;
    }
}

@media (min-width: 1061px) {
    #jsd-widget:not(.login) {
        top: 30px;
    }
}

@media (min-width: 992px) and (max-width: 1060px) {
    #jsd-widget:not(.login) {
        top: -7px;
        right: 0px;
    }
}
