.App__wrapper {
    width: 100%;
    height: 100%;
    main,
    .App {
        width: 100%;
        height: 100%;
    }
    .Simple {
        height: 100% !important;
        width: 100% !important;
        background-repeat: no-repeat;
        cursor: zoom-out;
    }
}
.zoomImg {
    will-change: transform;
  }