.member-view-main {
    height: 100%;
    overflow: auto;

    .member-content {
        // padding: 20px;
        width: 80%;
    }

    .p-5 {
        padding: 48px;
    }

    .member-border {
        border: 2px solid #dee2e6;
        box-shadow: 0px 0px 8px -1px;
    }

    @media only screen and (max-width: 600px) {
        .member-content {
            width: 100%;
        }
        .p-5 {
            padding: 10px !important;
        }
    }
    
    @media (min-width: 600px) and (max-width: 992px) {
        .member-content {
            width: 100%;
        }
        .p-5 {
            padding: 10px !important;
        }
    }
}